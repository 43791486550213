<template>
<footer class="bg-dark text-inverse">
	<div class="container py-13 py-md-15">
		<div class="row gy-6 gy-lg-0">
			<div class="col-md-4 col-lg-3">
				<div class="widget">
					<img class="mb-4"
							 src="@/assets/img/logo-light.png"
							 srcset="@/assets/img/logo-light@2x.png 2x"
							 alt="" />
					<p class="mb-4">© 2021 - 2022 Fraction.re SAS. <br class="d-none d-lg-block" />All rights reserved.</p>

					@ &nbsp; <a href="mailto:contact@berlcoin.com">contact@berlcoin.berlin</a>
					<br /> <i class="uil uil-phone"></i> &nbsp; <a href="tel:+33766461728">+33 (0) 970 465 468</a>
					<br /><i class="uil uil-edit"></i> &nbsp;
					<a :href="$t('link_contact')">{{$t('label_contact')}} </a> <br />
					<nav class="nav social social-white"
							 style="display:none">
						<a href="#"><i class="uil uil-twitter"></i></a>
						<a href="#"><i class="uil uil-facebook-f"></i></a>
						<a href="#"><i class="uil uil-dribbble"></i></a>
						<a href="#"><i class="uil uil-instagram"></i></a>
						<a href="#"><i class="uil uil-youtube"></i></a>
					</nav>
					<!-- /.social -->
				</div>
				<!-- /.widget -->
			</div>
			<!-- /column -->
			<div class="col-md-4 col-lg-3">
				<div class="widget">
					<h4 class="widget-title text-white mb-3"
							v-html="$t('apropos')" />
					<address class="pe-xl-15 pe-xxl-17"><b>SAS Fraction.re</b>
						<br />
						56 rue Monge<br class="d-none d-md-block" /> 75005 Paris, France
					</address>
					<a :href="$t('link_tou')">{{$t('label_tou')}} </a>
				</div>
				<!-- /.widget -->
			</div>
			<!-- /column -->
			<div class="col-md-4 col-lg-3">
				<div class="widget">
					<h4 class="widget-title text-white mb-3"
							v-html="$t('decouvirberlcoin')" />
					<ul class="list-unstyled  mb-0">
						<li><a href="/">Berlcoin</a></li>
						<li><a :href="$t('link_strategy')">{{$t('label_strategy')}}</a></li>
						<li><a :href="$t('link_benefit')">{{$t('label_benefit')}}</a></li>
						<li><a :href="$t('link_question')">{{$t('label_question')}}</a></li>
						<li><a :href="$t('link_risk')">{{$t('label_risk')}}</a></li>
						<li><a :href="$t('link_offer')">{{$t('label_invest')}}</a></li>
						<li><a :href="$t('link_sponsor')">{{$t('label_sponsor')}}</a></li>
					</ul>
				</div>

			</div>
			<!-- /column -->
			<div class="col-md-12 col-lg-3">
				<div class="widget">
					<h4 class="widget-title text-white mb-3"
							v-html="$t('newletter')" />
					<p class="mb-5"
						 v-html="$t('souscrivez')" />
					<div class="newsletter-wrapper">

						<div id="mc_embed_signup2">

							<div class="mc-field-group input-group form-floating">
								<input v-model="email"
											 type="email"
											 value=""
											 name="EMAIL"
											 class="required email form-control"
											 placeholder="Email Address"
											 id="mce-EMAIL2">
								<label for="mce-EMAIL2"> Email</label>
								<input @click="form()"
											 type="submit"
											 value="Envoyer"
											 name="subscribe"
											 id="mc-embedded-subscribe2"
											 class="btn btn-primary">
							</div>

						</div>
						<!--End mc_embed_signup-->
					</div>
					<!-- /.newsletter-wrapper -->
				</div>
				<!-- /.widget -->
			</div>

			<span class="mt-10"
						style="font-size:80%">
				{{$t('foot0')}}

				{{$t('foot1')}}
				{{$t('foot2')}}
				<br /><b> {{$t('foot3')}}
				</b>
			</span> <!-- /column -->
		</div>
		<!--/.row -->
	</div>
	<!-- /.container -->
</footer>
</template>


<script>
export default {
	name: 'footer2',
	components: {},
	props: {},
	data: () => ({
		email: undefined,
	}),
	beforeCreate: function () {},
	created: function () {},
	beforeMount: function () {},
	mounted: function () {},
	beforeUpdate: function () {},
	updated: function () {},
	beforeDestroy: function () {},
	destroyed: function () {},
	computed: {},
	methods: {



		form() {
			let opt = [];
			opt["api"] = "email"
			opt["origine"] = "berlcoin"
			opt["debug"] = false
			opt["email"] = this.email

			this.$store.dispatch('post', opt).then(response => {
				if (response != undefined) this.email = "c'est fait";

			})

		},

	},
	watch: {}
}
</script>

<style scoped>
</style>
