<template>
<nav class="navbar navbar-expand-lg center-nav navbar-dark navbar-bg-dark">
	<div class="container flex-lg-row flex-nowrap align-items-center">
		<div class="navbar-brand w-100">
			<a :href="'/'+	 locale+'/home'">
				<img src="@/assets/img/logo-light.png"
						 srcset="@/assets/img/logo-light@2x.png 2x"
						 alt="" />
			</a>
		</div>
		<div class="navbar-collapse offcanvas-nav">
			<div class="offcanvas-header d-lg-none d-xl-none">
				<a :href="'/'+	 locale+'/home'">
					<img src="@/assets/img/logo-light.png"
							 srcset="@/assets/img/logo-light@2x.png 2x"
							 alt="" /></a>

				<button type="button"
								class="btn-close btn-close-white offcanvas-close offcanvas-nav-close"
								aria-label="Close"></button>
			</div>
			<ul class="navbar-nav">
				<li class="nav-item"><a class="nav-link"
						 :href="$t('link_benefit')">{{$t('label_benefit')}}</a></li>
				<li class="nav-item"><a class="nav-link"
						 :href="$t('link_strategy')">{{$t('label_strategy')}}</a></li>
				<li class="nav-item"><a class="nav-link"
						 :href="$t('link_question')">{{$t('label_question')}}</a></li>
				<li class="nav-item"><a class="nav-link"
						 :href="$t('link_risk')">{{$t('label_risk')}}</a></li>
				<li class="nav-item"><a class="nav-link"
						 :href="$t('link_property')">{{$t('label_property')}}</a></li>
				<li class="nav-item"><a class="nav-link"
						 :href="$t('link_sponsor')">{{$t('label_sponsor')}}</a></li>
			</ul>




		</div>
		<!-- /.navbar-collapse -->
		<div class="navbar-other w-100 d-flex ms-auto">
			<ul class="navbar-nav flex-row align-items-center ms-auto "
					data-sm-skip="true">
				<li class="nav-item dropdown language-select text-uppercase d-lg-none">
					<a class="nav-link dropdown-item  "
						 href="#"
						 role="button"
						 data-bs-toggle="dropdown"
						 aria-haspopup="true"
						 aria-expanded="false"><i class="uil uil-align-justify"></i></a>
					<ul class="dropdown-menu">
						<li class="nav-item"><a class="dropdown-item"
								 :href="$t('link_benefit')">{{$t('label_benefit')}}</a></li>
						<li class="nav-item"><a class="dropdown-item"
								 :href="$t('link_strategy')">{{$t('label_strategy')}}</a></li>
						<li class="nav-item"><a class="dropdown-item"
								 :href="$t('link_question')">{{$t('label_question')}}</a></li>
						<li class="nav-item"><a class="dropdown-item"
								 :href="$t('link_risk')">{{$t('label_risk')}}</a></li>
						<li class="nav-item"><a class="dropdown-item"
								 :href="$t('link_property')">{{$t('label_property')}}</a></li>
						<li class="nav-item"><a class="dropdown-item"
								 :href="$t('link_sponsor')">{{$t('label_sponsor')}}</a></li>
					</ul>

				</li>
				<li class="nav-item dropdown language-select text-uppercase d-none d-md-block">
					<a class="nav-link dropdown-item dropdown-toggle"
						 href="#"
						 role="button"
						 data-bs-toggle="dropdown"
						 aria-haspopup="true"
						 aria-expanded="false">{{ locale}}</a>
					<ul class="dropdown-menu">
						<li class="nav-item dropdown-item"
								@click="lang('fr')"> FR</li>

						<li class="nav-item dropdown-item"
								@click="lang('en')"> EN</li>
					</ul>

				</li>
				<li class="nav-item  ">
					<a :href="$t('link_newinvest')"
						 class="btn btn-sm btn-success  ">{{$t('label_invest')}}</a>

				</li>

			</ul>
		</div>
	</div>
</nav>
</template>
<script>
export default {
	name: 'nav1',
	components: {},
	props: {},
	data: () => ({

	}),
	beforeCreate: function () {},
	created: function () {},
	beforeMount: function () {},
	mounted: function () {

	},
	beforeUpdate: function () {},
	updated: function () {},
	beforeDestroy: function () {},
	destroyed: function () {},
	computed: {
		locale() {
			return this.$i18n.locale
		}
	},
	methods: {
		lang(lang) {

			this.$i18n.locale = lang;
			//	this.locale = lang;
		}
	},
	watch: {

	}
}
</script>

<style scoped>
</style>
