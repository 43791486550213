import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const axios = require("axios");

export default new Vuex.Store({
  state: {
    //  https: "http://localhost:78/api/",
   https: "https://api.fraction.re/",
  //  https: "http://localhost:82/",
    session_token: "",
  },
  mutations: {},

  getters: {
    token: state => {
      return state.session_token;
    },
    https: state => {
      return state.https;
    }
  },
  actions: {

    /*  ------------------------------------------------------------------------------------------------------
    -------------------------------------------------------------------- AXIOS ---------------------------
    ------------------------------------------------------------------------------------------------------*/
    post(context, opt) {
      //    console.log("APPEL post " + opt["api"]);
      //    console.log(opt);
      // ------------ definition de l'api et des parametres
      let data = new FormData();
      let api = this.getters.https + opt["api"] + ".php";
      opt["token"] = this.getters.token;

      // ------------ defini les parametres du post
      for (let [key, value] of Object.entries(opt)) {
        if (key != "api") data.append(key, value);
      }

      // ------------ affichage du debug
      if (opt["debug"]) {
        let log = "";
        let logget = "";
        for (var pair of data.entries()) {
          log += "$_POST['" + pair[0] + "']= '" + pair[1] + "' ;\n";
          logget += "&" + pair[0] + "=" + pair[1];
        }
        console.log("APPEL API  : " + api);
        console.log(log + "\n" + api + "?" + logget + "\n");
      }

      // ------------- l'envoi au serveur et la gestion de la reponse

      return axios
        .post(api, data)
        .then(response => {
          if (opt["debug"]) {
            console.log("Retour axios de " + opt["api"] + " : " + response.data);
            console.log(response.data);
          }
          return Promise.resolve(response);
        })
        .catch(error => {
          if (opt["debug"]) {
            console.log("Une erreur est survenue");
            console.log("error headers " + " " + error.response.headers);
            console.log(error.response.headers);
            console.log("error status " + " " + error.response.status);
            console.log("error data " + " " + error.response.data);

            if (error.response.status == 401) this.state.session_token = "";
          }
          return Promise.resolve(error);
        });
    }


  },
  modules: {}
})
