import Vue from 'vue'
import VueRouter from 'vue-router'


Vue.use(VueRouter)

const routes = [{
    path: '/',
    name: 'Home',
    props: {
      language: 'FR'
    },
    component: () => import('../views/Home.vue')
  },
  {
    path: '/:lang/home',
    name: 'home',
    component: () => import('../views/Home.vue')
  },
  {
    path: '/:lang/contact',
    name: 'contact',
    component: () => import('../views/contact_form.vue')
  },
  {
    path: '/:lang/offer',
    name: 'offer',
    component: () => import('../views/offer.vue')
  },
  {
    path: '/:lang/newinvest',
    name: 'newinvest',
    component: () => import('../views/newinvest.vue')
  },
  {
    path: '/:lang/sponsoring',
    name: 'sponsoring',
    component: () => import('../views/sponsoring.vue')
  },
  {
    path: '/:lang/benefit',
    name: 'benefit',
    component: () => import('../views/benefit.vue')
  },
  {
    path: '/:lang/sell',
    name: 'sell',
    component: () => import('../views/sell.vue')
  },
  {
    path: '/:lang/strategy',
    name: 'strategy',
    component: () => import('../views/strategy.vue')
  },
  {
    path: '/:lang/risk',
    name: 'risk',
    component: () => import('../views/risk.vue')
  },
  {
    path: '/:lang/question',
    name: 'question',
    component: () => import('../views/question.vue')
  },
  {
    path: '/:lang/tou',
    name: 'tou',
    component: () => import('../views/tou.vue')
  },
  {
    path: '/:lang/property',
    name: 'property',
    component: () => import('../views/realestate.vue')
  },
  {
    path: '/:lang/View_property/:id',
    name: 'View_property',
    component: () => import('../views/View_property.vue')
  },
  {
    path: '/:lang/invest',
    name: 'invest',
    component: () => import('../views/invest.vue')
  },
  {
    path: '/:lang/francais',
    name: 'francais',
    component: () => import('../smart/francais.vue')
  },

  {
    path: '/:lang/wallet',
    name: 'wallet',
    component: () => import('../smart/wallet.vue')
  },
  {
    path: '/:lang/open',
    name: 'open',
    component: () => import('../smart/smart_open.vue')
  },
  {
    path: '/:lang/staking',
    name: 'staking',
    component: () => import('../smart/staking.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
