<template>
<div id="app">

	<nav1 />

	<router-view />
	<footer2 />
</div>
</template>



<script>
import footer2 from "@/components/footer2.vue";
import nav1 from "@/components/nav1.vue";



export default {
	name: 'app',
	components: {
		nav1,
		footer2
	},
	props: {},
	data: () => ({}),
	beforeCreate: function () {},
	created: function () {},
	beforeMount: function () {},
	mounted: function () {},
	beforeUpdate: function () {},
	updated: function () {},
	beforeDestroy: function () {},
	destroyed: function () {},
	computed: {},
	methods: {
		allowCookies() {
			this.$matomo.rememberConsentGiven();
		}
	},
	watch: {}
}
</script>

<style>
@import 'assets/css/plugins.css';
@import 'assets/css/style.css';
@import 'assets/css/colors/purple.css';
@import 'assets/css/fonts/thicccboi.css';

#app {
	font-family: Avenir, Helvetica, Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	color: #2c3e50;
}

#nav {
	padding: 30px;
}

#nav a {
	font-weight: bold;
	color: #2c3e50;
}

#nav a.router-link-exact-active {
	color: #42b983;
}
</style>
